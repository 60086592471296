.dashboardLink {
  margin: 0 20px 20px 0;
  border: 1px solid #dbe2e6;
  padding: 10px;
  border-radius: 10px;
  display: inline-block;
  width: 100%;
  transition: 0.2s ease all;

  &:hover {
    background-color: #ebf7ff;
    border-color: #ebf7ff;
  }

  >div {
    display: flex;
    gap: 10px;
  }
}

.total-items-badge {
  display: inline-block;
  padding: 10px;
  font-size: 90%;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 5px;
  background-color: #007bff;
  color: white;

  span {
    font-size: 16px;
    font-weight: 700;
  }
}

.deleteButton {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.2s ease all;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

  &:hover {
    background-color: #c82333;
  }
}

.modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  height: 350px;
  background-color: white;
  margin: 0 auto !important;
  padding: 0 !important;
  border: 1px solid #ccc; // added border
  border-radius: 5px; // added border radius
  position: fixed; // added position
  top: 50%; // added top
  left: 50%; // added left
  transform: translate(-50%, -50%); // added transform
}

.modal-title {
  text-align: center;
  padding: 50px;
}

.modal-buttons {
  margin-top: 100px;
  display: flex;
  width: 100%;
}

.modal-button {
  width: 50%;
  font-weight: bold; // make text bold
  padding: 10px 0; // increase vertical padding
}

.events {
  margin-top: 1rem;
}

.events-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.event-box {
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 1rem;
  margin: 0.5rem 0;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.event-title {
  font-weight: bold;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.event-day,
.event-updated {
  margin-bottom: 0.25rem;
}


.data {
  padding: 1rem;
  margin-top: 80px;
}

.data-title {
  font-size: 2rem;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.data-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 1rem;
}

.data-box {
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 1rem;
  margin: 0.5rem;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  min-width: 270px;

  h2 {
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid lightgrey;
  }

  @media (max-width: 768px) {
    min-width: 100%;
    max-width: 100%;
  }
}

.data-key {
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  margin-right: 10px;
}

.data-value {
  margin-bottom: 0.25rem;
}

.data-subkey {
  font-weight: bold;
}

.data-row {
  display: flex;
  justify-content: space-between;
  flex: 1;

  a {
    color: #007bff;
    text-transform: uppercase;
    font-size: 0.8rem;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      color: orange;
    }
  }
}

.data-row-items {
  display: flex;
  flex-direction: column;
}

.data-subgroup {
  display: flex;
  flex-direction: column;

  strong {
    margin-right: 0.5rem;
  }
}

.data-user-box {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  border: 1px solid lightgrey;
  padding: 10px;

  &:hover {
    background-color: #f8f9fa;
  }

  p {
    margin: 0;
    color: black;
    text-transform: none;
    font-size: 12px;
  }

  &.male {
    background-color: #ecfaff;

    &:hover {
      background-color: #e6f7ff;
    }
  }

  &.female {
    background-color: #ffedf0;

    &:hover {
      background-color: #ffe6eb;
    }
  }
}

.data-user-picture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.data-user-image {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  margin: 3px;
}

.row-hover {
  td {
    line-height: 14px;
    padding-bottom: 2px;
    padding-top: 2px;
    vertical-align: middle;
  }

  &:hover {
    td {
      box-shadow: none;
      background-color: bisque !important;
    }
  }
}

textarea.form-control {
  height: 150px;
}