// ChatStyles.scss
@import 'variables';

.chat-section {
  flex: 1;
  border-radius: 5px;

  .chat-messages {
    min-height: 200px;
    max-height: 300px;
    overflow-y: auto;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    .chat-message {
      display: flex;
      align-items: flex-end;
      margin-bottom: 10px;

      &.own-message {
        flex-direction: row-reverse;
        text-align: right;

        .message-content {
          background-color: #dcf8c6;
        }
      }

      &.other-message {
        text-align: left;
      }

      .message-avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin: 0 10px 0 0;
        object-fit: cover;
      }

      .message-content {
        max-width: 70%;
        background-color: #ffffff;
        padding: 3px 8px;
        border-radius: 8px;
        font-size: 12px;
      }

      .message-text {
        white-space: pre-wrap;
        word-break: break-word;
        font-size: 12px;
      }
    }
  }

  .chat-input-form {
    margin-top: 10px;

    .form-group {
      display: flex;
      align-items: center;

      input {
        flex: 1;
        margin-right: 10px;
      }

      button {
        flex-shrink: 0;
      }
    }
  }

  .countdown-message {
    margin-bottom: 10px;
    font-weight: bold;
    color: $danger-color;
  }
}