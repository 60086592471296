// EventDetail.scss
@import 'variables';
@import 'chat';

.event-detail {
  margin-top: 15px;
  display: flex;
  gap: 10px;
  padding-bottom: 35px;

  .event-info {
    flex: 1;
  }

  .event-actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;

    button {
      margin-left: 5px;
    }
  }

  p {
    margin-bottom: 10px;
  }

  .users-active,
  .users-waiting {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 15px;
    max-width: 400px;
    flex-wrap: wrap;

    .user-card {
      display: flex;
      align-items: center;
      border: 1px solid $border-color;
      border-radius: 5px;
      padding: 8px 12px;
      margin-bottom: 10px;
      transition: background-color $transition-duration $transition-ease,
        border-color $transition-duration $transition-ease;
      position: relative;
      cursor: pointer;
      max-width: 195px;


      img {
        width: 40px;
        height: 40px;
        border-radius: 50%; // Make the image round
        margin-right: 10px;
        object-fit: cover;
      }

      span {
        font-weight: bold;
      }

      &:hover {
        background-color: $gray-light-color;
        border-color: $info-color;
      }

      button {
        margin-left: auto;
      }
    }
  }

  .current-action {
    position: absolute;
    bottom: 0px;

    button {
      margin-left: 5px;
    }
  }

  .cooldown-remaining {
    position: absolute;
    bottom: 40px;
  }
}

// on screens smaller than 1024px
@media (max-width: 1024px) {
  .event-detail {
    display: block;
  }

  // .users-active,
  // .users-waiting {
  //   flex-direction: column;
  // }
}