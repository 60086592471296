@import 'variables';

#thomasForm {
  background-color: #fff9ce;
  padding: 10px;

  .thumbnails {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    .thumbnail {
      position: relative;
      width: 230px;
      height: 230px;
      border-radius: $thumbnail-border-radius;
      overflow: hidden;
      cursor: pointer;
      transition: transform $transition-duration $transition-ease;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: $thumbnail-border-radius;
      }

      &:hover {
        transform: scale(1.05);
      }

      .thumbnail-loading {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.7); // Semi-transparent overlay
        z-index: 1;

        .spinner-border {
          width: 2rem;
          height: 2rem;
        }
      }
    }
  }
}

.page-content.thomas {
  .thomas-container {
    padding: 10px;

    .card-body {
      border: 1px solid #dcdcdc;
      border-radius: 5px;
    }

    .top-bar {
      position: relative;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 20px;

      .add-user-btn {
        display: flex;
        align-items: center;
        margin-right: 20px;
        font-size: 14px;

        i {
          margin-right: 5px;
        }
      }

      .start-thomas-btn {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 14px;

        i {
          margin-right: 5px;
        }
      }

      .current-action {
        position: absolute;
        right: 90px;
      }

      .city-buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .city-button {
          display: flex;
          align-items: center;
          border-radius: 20px;
          padding: 5px 15px;
          font-size: 1rem;
          position: relative;
          cursor: pointer;
          transition: background-color 0.2s ease-in-out;
          color: black;
          background-color: white;

          i {
            margin-right: 5px;
          }

          .user-count {
            background-color: $primary-color;
            color: $white-color;
            border-radius: 50%;
            padding: 2px 8px;
            margin-left: 8px;
            font-size: 0.8rem;
          }

          &:hover {
            background-color: $gray-light-color;
          }

          &.city-button-selected {
            background-color: $primary-color;
            color: $white-color;
            border-color: $primary-color;
          }
        }
      }
    }

    .user-list-column {
      width: 300px;
      flex-shrink: 0;
      overflow-y: auto;
      max-height: calc(100vh - 150px);
    }

    .user-list {
      display: flex;
      flex-direction: column;
    }

    .user-card {
      background-color: $white-color;
      border: 1px solid $border-color;
      border-radius: 8px;
      padding: 10px 15px;
      margin-bottom: 5px;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      transition: background-color 0.2s ease-in-out;
      position: relative;
      width: 100%;

      &:hover {
        background-color: $gray-light-color;
      }

      &.selected {
        border-color: $primary-color;
        box-shadow: 0 0 3px rgba($primary-color, 0.5);
        background-color: $gray-light-color;
      }

      .user-card-content {
        display: flex;
        flex-direction: column;

        .user-card-header {
          display: flex;
          align-items: center;

          .user-avatar {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            object-fit: cover;
            margin-right: 15px;
          }

          .user-details {
            h5 {
              margin: 0;
              font-size: 14px;
              color: $dark-color;
            }

            p {
              margin: 0;
              font-size: 0.85rem;
              color: $gray-color;
            }
          }
        }

        .user-card-footer {
          .user-events {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;

            .event-count {
              padding: 5px 8px;
              border-radius: 5px;
              font-size: 0.75rem;
              color: $white-color;

              &.active-events {
                background-color: $success-color;
              }

              &.expired-events {
                background-color: $danger-color;
              }

              &.awaiting-events {
                background-color: $warning-color;
              }
            }
          }
        }
      }
    }

    .user-detail-column {
      flex: 1;
      padding-left: 20px;
      overflow-y: auto;
      max-height: calc(100vh - 150px);
    }

    .user-detail-card {
      border: none;
      margin: 0;

      .user-detail-title {
        display: flex;
        align-items: center;
        gap: 15px;
        font-size: 1.5rem;
        margin-bottom: 1rem;

        .user-profile-image {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          object-fit: cover;
          margin-right: 15px;
        }
      }

      .user-detail-info {
        font-size: 0.9rem;

        p {
          margin-bottom: 10px;
        }

        ul {
          list-style-type: disc;
          padding-left: 20px;
        }
      }
    }

    .new-user-form {
      max-width: 600px;
      margin: 0 auto;

      h2 {
        margin-bottom: 20px;
      }

      .form-group {
        margin-bottom: 15px;
      }

      button {
        margin-top: 10px;
      }
    }

    .no-users {
      text-align: center;
      margin-top: 50px;

      p {
        font-size: 1.5rem;
        margin-bottom: 20px;
      }
    }

    .loading-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 80vh;
    }
  }
}

.user-detail-card {
  border: none;
  margin: 20px;

  .user-detail-nearby-events {
    padding: 10px;
  }

  .user-detail-title {
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 1.5rem;
    margin-bottom: 1rem;

    .user-profile-image {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 15px;
    }
  }

  .user-action-menu {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 9999999;
  }

  .user-detail-list {
    font-size: 0.9rem;
    flex-direction: row;
    flex-wrap: wrap;

    .interest-badge {
      background-color: $primary-color;
      color: $white-color;
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }

  .pictures-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;

    .picture-thumbnail {
      width: $thumbnail-size;
      height: $thumbnail-size;
      border: 1px solid $border-color;
      border-radius: 4px;
      overflow: hidden;
      cursor: pointer;
      position: relative;
      transition: transform $transition-duration $transition-ease;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform $transition-duration $transition-ease;
      }

      &:hover img {
        transform: scale(1.1);
      }
    }
  }
}

.form-pictures {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.form-label {
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 3px;
}

// Dropzone Styling
.dropzone {
  cursor: pointer;
  max-width: 230px;
  height: 200px;
  border: 5px dashed #9f7c7c !important;
  display: flex;
  transition: background-color $transition-duration $transition-ease;
  flex: 1;
  margin-right: 20px;

  &:hover {
    background-color: $gray-light-color;
  }

  p {
    align-content: center;
  }
}

// Thumbnails Styling
.thumbnails {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex: 1;

  .thumbnail {
    position: relative;
    width: $thumbnail-size;
    height: $thumbnail-size;
    border-radius: $thumbnail-border-radius;
    overflow: hidden;
    cursor: pointer;
    transition: transform $transition-duration $transition-ease;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $thumbnail-border-radius;
    }

    &:hover {
      transform: scale(1.05);
    }

    .thumbnail-loading {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 1;

      .spinner-border {
        width: 2rem;
        height: 2rem;
      }
    }

    .btn-danger {
      position: absolute;
      top: 5px;
      right: 5px;
      background-color: rgba(220, 53, 69, 0.8);
      border: none;
      padding: 2px 6px;
      border-radius: 50%;

      &:hover {
        background-color: $danger-color;
      }
    }
  }
}

.header-toggle {
  cursor: pointer;
  border: 1px solid $border-color;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px;
  padding: 10px;
  margin-right: -10px;
  margin-left: -10px;
  margin-top: 0;
  background-color: aliceblue;

  &:hover {
    background-color: rgba($primary-color, 0.1);
  }
}

.event-list-item {
  background-color: #fbfff4;

  .event-actions {
    position: absolute;
    right: 50px;
    top: 19px;
  }
}

// image uploader
.image-uploader {
  display: flex;
}

.action-message {
  position: absolute;
  bottom: -30px;
  background-color: #e7f3fe;
  border-left: 6px solid #2196F3;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
}

.error-message {
  background-color: #f8d7da;
  border-left: 6px solid #f44336;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
}

.current-action {
  background-color: #fff3cd;
  border-left: 6px solid #ffecb5;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
}