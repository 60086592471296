// SCSS Variables
$modal-background: rgba(0, 0, 0, 0.8);

// Modal Styles
.custom-modal-backdrop {
  background-color: transparent;
}

.modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: $modal-background;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  animation: fadeIn 0.3s;
}

.modal-content {
  position: relative;
  // max-width: 90%;
  // max-height: 90%;
}

.full-image {
  border-radius: 8px;
  // box-shadow: 0 0 10px #000;
}

/* Close Button */
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ffffff;
  color: #2d0101;
  font-size: 34px;
  line-height: 42px;
  border: 1px solid #fff;
  box-shadow: 0 0 2px #000;
  border-radius: 50%;
  width: 44px;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1001;
  transition: all 0.3s;

  &:hover {
    color: white;
    background-color: #333;
  }
}

/* Navigation Buttons */
.nav-button {
  position: absolute;
  background-color: #feffff;
  color: black;
  border: 1px solid #333;
  box-shadow: 0 0 2px #000;
  border-radius: 50%;
  width: 50px;
  font-size: 30px;
  line-height: 30px;
  top: 50%;
  transform: translateY(-50%);
  padding: 10px;
  cursor: pointer;
  z-index: 1002;
  transition: all 0.3s;

  &.left {
    left: 20px;
  }

  &.right {
    right: 20px;
  }

  &:hover {
    background-color: #333;
    color: #fff;
  }
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Fix Modal Height */
.modal {
  max-height: initial;
  height: auto;
}

.modal-dialog.custom-modal {
  margin: 0;
}